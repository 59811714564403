import React from "react";
import PropTypes from "prop-types";
import { Button as ThemeUiButton } from "theme-ui";

const Button = ({ variant, children, ...props }) => (
  <ThemeUiButton
    style={{
      cursor: "pointer",
      ":hover": {
        opacity: 0.5,
      },
    }}
    {...props}
    variant={variant}
  >
    {children}
  </ThemeUiButton>
);

Button.propTypes = {
  variant: PropTypes.oneOf([
    "variant1",
    "variant2",
    "variant3",
    "variant6",
    "iconButton",
  ]),
  onClick: PropTypes.func,
  children: PropTypes.any,
};

export default React.memo(Button);
